<template>
  <v-btn
    depressed
    class="mr-2"
    color="primary"
    @click="create"
  >
    Create
    <v-icon right dark>mdi-plus-thick</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CreateButton",
  methods: {
    create() {
      this.$emit("create");
    }
  }
};
</script>
